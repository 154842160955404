/* Dashboard.css */
.dashboard-container {
    display: flex;
    height: 100vh; /* Full height */
}

.drawer {
    width: 240px;
    flex-shrink: 0;
    width: 60px; /* Default width */
    transition: width 0.3s ease;
    overflow-x: hidden;
}

.drawer:hover {
    width: 240px; /* Expanded width */
}

.drawer .MuiDrawer-paper {
    width: 60px; /* Default width */
    transition: width 0.3s ease;
}

.drawer:hover .MuiDrawer-paper {
    width: 240px; /* Expanded width */
}

.dashboard-background {
    background-image: url('https://images.unsplash.com/photo-1554147090-e1221a04a025?q=80&w=2896&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: center;
}

.dashboard-content {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding-right: 0; /* Remove right margin */
}
