body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}

html, body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  height: 100%;
}

/* Background images for specific routes */
.login-background {
  background-image: url('https://images.unsplash.com/photo-1513542789411-b6a5d4f31634?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  /* background-image: url('https://images.unsplash.com/photo-1554147090-e1221a04a025?q=80&w=2896&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); */
  background-size: cover;
  background-position: center;
}

/* Landing page styles */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.landing-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.landing-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.landing-button {
  margin-top: 2rem;
}

.modal-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background-color: white;
  box-shadow: 24;
  padding: 16px;
  border-radius: 8px;
}

.modal-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.consent-banner-text {
  font-size: 0.875rem;
  color: #333;
}

.consent-banner-button {
  margin-left: 16px;
}

.policy-container {
  overflow-y: auto;
  height: calc(100vh - 50px); /* Adjust as needed to leave space for headers/footers */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: background for readability */
}
