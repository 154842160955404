/* Add this CSS to your App.css or a new CSS file */

/* Ensure the body and root elements have 100% height for proper scrolling */
html,
body,
#root {
  height: 100%;
  margin: 0;
}

.privacy-policy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent background */
}

.privacy-policy-content {
  max-width: 800px;
  width: 100%;
  margin-bottom: 20px;
}

.privacy-policy-background {
  background-image: url('https://images.unsplash.com/photo-1633265486064-086b219458ec?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
}