.study-plan-card {
  min-width: 275px;
  max-width: 345px;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s ease;
  /* background-image: url('https://images.unsplash.com/photo-1563291074-2bf8677ac0e5?q=80&w=2650&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); 
    background-size: cover;
    background-position: center; */
  position: relative;
  border-radius: 8px;
}

.study-plan-card:hover {
  opacity: 1;
}

.study-plan-card-content {
  padding: 16px;
  border-radius: 8px;
  position: relative;
}

.study-plan-card-content p,
.study-plan-card-content .MuiTypography-root {
  color: #3e64de;
}

.study-plan-new-plan {
    background-image: url('https://images.unsplash.com/photo-1514782831304-632d84503f6f?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); 
    background-size: cover;
    background-position: center;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #3e64de;
}

.study-console-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}

.study-plan-form-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  /* Add a unit */
  background-color: white;
  /* This seems to be a value from a theme. You'll need to replace it with an actual color value, e.g., #ffffff */
  border: 2px solid #000;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  /* Example shadow, adjust as needed */
  padding: 16px;
  /* Assuming 'p: 4' meant 16px; adjust as needed */
}