/* auth.css */

/* Set max height and enable scrolling */
.MuiContainer-root {
    max-height: 500px;
    overflow-y: auto;
}

/* Remove margin-top from MuiBox-root */
.MuiBox-root {
    margin-top: 0 !important;
}

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    max-width: 500px;
}

/* Ensure "Forgot password" and "Already have an account?" share the max width */
.auth-links {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.auth-copyright {
    color: #6c757d;
}


