.profileForm {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
}

.formGroup input,
.formGroup select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block; /* Make the button take the full width */
  width: 100%; /* Ensure it fills the container */
  margin-bottom: 10px; /* Add space between this and the next button */
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.formActions {
  display: flex;
  flex-direction: column; /* List the buttons vertically */
}

.formActions button {
  margin-top: 10px; /* Add some space between the buttons */
}

/* Custom styles for specific buttons */
button.resetPassword {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button.resetPassword:hover {
  background-color: darkorange;
}

button.deleteAccount {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button.deleteAccount:hover {
  background-color: darkred;
}