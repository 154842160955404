/* FileListModal.css */
.fileListModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff; /* Assuming 'background.paper' refers to a white background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Example shadow, adjust as needed */
    padding: 16px; /* Assuming '4' is equivalent to '16px' */
  }
  
  /* Adjusts the width of ListItemButton to the minimum content size */
.min-content-list-item {
    width: min-content;
}
 .list-item-button {
    display: flex;
    flex-direction: row-reverse;
 }