/* StudyPlanCard.css */
.study-plan-card {
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.study-plan-card:hover {
  opacity: 1;
}

.study-plan-card-content {
  color: rgba(255, 255, 255, 0.87);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Add shadow for better contrast */
}

.study-plan-form-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background-color: white;
  box-shadow: 24;
  padding: 16px;
  border-radius: 8px;
}
