/* PulsedCalendar.css */

/* General Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
  }
  
  .calendar-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  button {
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Calendar Styles */
  .rbc-calendar {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .rbc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #f9f9f9;
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
  }
  
  .rbc-toolbar button {
    background: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .rbc-toolbar button:hover {
    background: #0056b3;
  }
  
  .rbc-header {
    padding: 10px;
    background: #f1f1f1;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  .rbc-month-view,
  .rbc-time-view {
    border-radius: 0 0 8px 8px;
  }
  
  .rbc-month-row {
    border-bottom: 1px solid #ddd;
  }
  
  .rbc-date-cell {
    padding: 10px;
    cursor: pointer;
  }
  
  .rbc-date-cell:hover {
    background: #e6f7ff;
  }
  
  .rbc-selected-cell {
    background: #007bff;
    color: white;
  }
  
  .rbc-event {
    background: #007bff;
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Modal Styles */
  .pulsed-event-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .pulsed-event-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pulsed-event-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .pulsed-event-actions button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .pulsed-event-actions .MuiButton-containedPrimary {
    background-color: #007bff;
  }
  
  .pulsed-event-actions .MuiButton-outlinedSecondary {
    background-color: #f1f1f1;
  }
  