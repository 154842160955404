/* Scope your styles to ensure they apply within the .profileForm context */
.profileForm .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Adjust as needed */
  }
  
  .profileForm .avatarContainer img {
    max-width: 100px; /* Adjust the size as needed */
    max-height: 100px; /* Adjust the size as needed */
    border-radius: 50%; /* Makes the image circular */
    margin-bottom: 10px; /* Space between the image and the input */
  }
  
  .uploadButton {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    /* Add more styling as needed */
  }
  